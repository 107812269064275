<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="12" class="text-left">
          <b-row>
            <b-col md="12" class="mb-2"><h4>User Data</h4></b-col>
            <b-col md="12">
              <b-row>
                <b-col md="2">
                  <b-media
                    class="d-flex justify-content-center mb-0 flex-wrap ml-2"
                    no-body
                  >
                    <b-media-aside>
                      <b-avatar
                        :src="model.profile.avatar"
                        class="mb-1"
                        size="90px"
                      ></b-avatar>
                    </b-media-aside>
                  </b-media>
                </b-col>
                <b-col md="10">
                  <b-row>
                    <b-col md="6">
                      <p class="mb-0"><b>First Name</b></p>
                      <p>{{ model.profile.firstName }}</p>
                    </b-col>
                    <b-col md="6">
                      <p class="mb-0"><b>Last Name</b></p>
                      <p>{{ model.profile.lastName }}</p>
                    </b-col>

                    <b-col md="6">
                      <p class="mb-0"><b>Email</b></p>
                      <p>{{ model.username }}</p>
                    </b-col>

                    <b-col md="6">
                      <p class="mb-0"><b>Phone Number</b></p>
                      <p>{{ model.profile.phoneNumber }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" class="text-left mt-1">
          <b-row>
            <b-col md="12"><h4>Contributions Data</h4></b-col>
            <b-col md="12">
              <div>
                <!-- types -->
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item
                    v-for="(i, index) in contribution"
                    :key="index"
                    :title="i.name"
                  >
                    <b-row>
                      <b-col md="2" class="text-left">
                        <b-row>
                          <b-col md="12">
                            <b-media
                              class="d-flex justify-content-center mb-0 flex-wrap"
                              no-body
                            >
                              <b-media-aside>
                                <b-avatar
                                  :src="i.logo"
                                  class="mb-1"
                                  size="90px"
                                ></b-avatar>
                              </b-media-aside>
                            </b-media>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="10" class="mt-1">
                        <b-row>
                          <b-col md="2">
                            <p
                              class="p-1 text-center text-capotalize font-weight-bolder"
                              style="border: 2px solid #333; border-radius: 5px"
                            >
                              {{ i.status }}
                            </p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <p class="mb-0"><b>Name</b></p>
                            <p>{{ i.name }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Phone Number</b></p>
                            <p>{{ i.phoneNumber }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Email</b></p>
                            <p>{{ i.email }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Certificate</b></p>
                            <b-link :href="i.certificate" target="_blank">
                              <p>{{ i.certificate }}</p>
                            </b-link>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Description</b></p>
                            <p>{{ i.description }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Website</b></p>
                            <p>{{ i.website }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'user' }"
      >
        Back
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BFormRadio,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    vSelect,
    BTab,
    BTabs,
    BFormRadio,
    BModal,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      collapseType: "margin",
      config: {
        api: "users",
        contribution: "users",
      },
      model: {
        profile: {},
      },
      contribution: [],
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data;
        _.getContibution();
      });
    },
    getContibution() {
      let _ = this;
      axios
        .get(_.config.api + "/" + this.$route.params.id + "/contributions")
        .then((response) => {
          let _ = this;
          _.contribution = response.data.rows;
          console.log(_.contribution);
        });
    },
  },
};
</script>
